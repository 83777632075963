import { Component, useState } from "react";
import { AiOutlineArrowDown } from "react-icons/ai";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "./style.css";

const About = () => {
  const [percentage, setPercentage] = useState(100);

  const handleGetInTouch = () => {
    // scroll to the contact section
    const contact = document.querySelector("#contact");
    contact.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <section className="about__container" id="about">
      <div className="container">
        <div>
          <h1>Innovative Solutions for Surgical Success</h1>
          <p className="text">
            Our drapes and linens blend innovation, quality, and reliability to
            ensure every operation is a success. Trust the experts, trust us!
            Experience surgical efficiency with our meticulously curated packs.
            From minor procedures to complex surgeries, our packs are designed
            to meet the highest standards. Elevate your practice with Trita
            Meds.
          </p>
          <div className="about__buttons">
            <button className="btn btn-primary" onClick={handleGetInTouch}>
              Get In Touch
            </button>
            <a href="#services">
              <span>Our Services</span>
              <AiOutlineArrowDown />
            </a>
          </div>
        </div>
        <div style={{ position: "relative" }}>
          <div className="about__image">
            <img
              src="https://images.pexels.com/photos/263337/pexels-photo-263337.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
              alt="about"
            />
          </div>
          <div className="about__progress">
            <div className="progress_bar">
              <CircularProgressbar value={percentage} text={`${percentage}%`} />
            </div>
            <h4>
              Customer Satisfaction
              <br />
              <small>Level is high</small>
            </h4>
          </div>
        </div>
      </div>
    </section>
  );
};
/*
class About extends Component {
  constructor() {
    super();
    this.state = {
      percentage: 75
    };
  }
  render() {
    return (
      <section className="about__container">
        <div className="container">
          <div>
            <h1>Advance Innovation For IT Solutions</h1>
            <p className="text">
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eos
              eligendi molestiae ex harum, sed animi at aliquam quaerat odit
              repudiandae dicta veniam, beatae aliquid, voluptatum nostrum saepe
              doloribus excepturi. Facere!
            </p>
            <div className="about__buttons">
              <button className="btn btn-primary">Get In Touch</button>
              <a href="#services">
                <span>Our Services</span>
                <AiOutlineArrowDown />
              </a>
            </div>
          </div>
          <div style={{ position: "relative" }}>
            <div className="about__image">
              <img
                src="https://st4.depositphotos.com/17586788/i/600/depositphotos_247586928-stock-photo-a-successful-business-girl-in.jpg"
                alt="about"
              />
            </div>
            <div className="about__progress">
              <div className="progress_bar">
                <CircularProgressbar
                  value={this.state.percentage}
                  text={`${this.state.percentage}%`}
                />
              </div>
              <h4>
                Business Growth
                <br />
                <small>Level is high</small>
              </h4>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
*/
export default About;
