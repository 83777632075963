// import { Component } from "react";
import Logo from "./Logo";
import Links from "./Links";
import Quote from "./Quote";
import Search from "./Search";
import "./style.css";

const Nav = () => {
  return (
    <div className="nav_wrapper">
      <div className="container container__nav">
        <Logo />
        <Links />
        <Quote />
        {/* <Search /> */}
      </div>
    </div>
  );
};

export default Nav;

/*
class Nav extends Component {
  render() {
    return (
      <nav>
        <div className="container container__nav">
          <Logo />
          <Links />
          <Quote />
          <Search />
        </div>
      </nav>
    );
  }
}

*/
