// import { Component } from "react";

// const Logo = () => {
//   return (
//     <div className="logo">
//       <h3>TRITA MEDS</h3>
//     </div>
//   );
// };
// export default Logo;
/*
class Logo extends Component {
  render() {
    return (
      <div className="logo">
        <h3>LOGO</h3>
      </div>
    );
  }
}
export default Logo;
*/
import React from "react";
import { ReactComponent as LogoSVG } from "../../assets/logo/logo3.svg";

const Logo = () => {
  return (
    <div className="logo">
      {/* <img src="/assets/logo/logo3.svg" alt="Logo" /> */}
      <LogoSVG />
      {/* <h3>TRITA MEDS</h3> */}
    </div>
  );
};

export default Logo;
