import React, { useState, useEffect, useRef } from "react";
import "./styles.css"; // Import CSS file for styling
import { Element } from "react-scroll";
const logos = [
  // Array of brand logos
  "/brands/brand1.png",
  "/brands/brand2.png",
  "/brands/brand3.png",
  // "/brands/brand4.png",
  // "/brands/brand5.png",
  // "/brands/brand6.png",
  // "/brands/brand7.png",
  // "/brands/brand8.png",
  // "/brands/brand9.png",
  // "/brands/brand10.png",
  // "/brands/brand11.png",
  // "/brands/brand12.png",
  // "/brands/brand13.png",
  // "/brands/brand14.png",
  // "/brands/brand15.png",
  // "/brands/brand16.png",
  // "/brands/brand17.png",
  // "/brands/brand18.png",
  // "/brands/brand19.png",
  // "/brands/brand20.png",
  // "/brands/brand21.png",
  // "/brands/brand22.png",
  // "/brands/brand23.png",
  // "/brands/brand24.png",
  // "/brands/brand25.png",
  // "/brands/brand26.png",
  // "/brands/brand27.png",
  // "/brands/brand28.png",
  // "/brands/brand29.png",
  // "/brands/brand30.png",
  // "/brands/brand31.png",
  // "/brands/brand32.png",
  // "/brands/brand33.png",
  // "/brands/brand34.png",
  // "/assets/brands/brand35.png",
  // "/assets/brands/brand36.png",
  // "/assets/brands/brand37.png",
  // "/assets/brands/brand38.png",
  // "/assets/brands/brand39.png",
  // "/assets/brands/brand40.png",
  // "/assets/brands/brand41.png",
  // "/assets/brands/brand42.png",
  // "/assets/brands/brand43.png",
  // "/assets/brands/brand44.png",
  // "/assets/brands/brand45.png",
  // "/assets/brands/brand46.png",
  // "/assets/brands/brand47.png",
  // "/assets/brands/brand48.png",
  // "/assets/brands/brand49.png",
  // "/assets/brands/brand50.png",
  // "/assets/brands/brand51.png",
  // "/assets/brands/brand52.png",
  // "/assets/brands/brand53.png",
  // "/assets/brands/brand54.png",
  // "/assets/brands/brand55.png",
  // "/assets/brands/brand56.png",
  // "/assets/brands/brand57.png",
  // "/assets/brands/brand58.png",
  // "/assets/brands/brand59.png",
  // "/assets/brands/brand60.png",
  // "/assets/brands/brand61.png",
  // "/assets/brands/brand62.png",
  // "/assets/brands/brand63.png",
  // "/assets/brands/brand64.png",
  // "/assets/brands/brand65.png",
  // "/assets/brands/brand66.png",
  // "/assets/brands/brand67.png",
  // "/assets/brands/brand68.png",
  // "/assets/brands/brand69.png",
  // "/assets/brands/brand70.png",
  // "/assets/brands/brand71.png",
  // "/assets/brands/brand72.png",
  // "/assets/brands/brand73.png",
  // "/assets/brands/brand74.png",
  // "/assets/brands/brand75.png",
  // "/assets/brands/brand76.png",
  // "/assets/brands/brand77.png",
  // "/assets/brands/brand78.png",
  // "/assets/brands/brand79.png",
  // "/assets/brands/brand80.png",
  // "/assets/brands/brand81.png",
  // "/assets/brands/brand82.png",
  // "/assets/brands/brand83.png",
  // "/assets/brands/brand84.png",
  // "/assets/brands/brand85.png",
  // "/assets/brands/brand86.png",
  // "/assets/brands/brand87.png",
  // "/assets/brands/brand88.png",

  // Add more logos as needed
];

const SlidingBrandCarousel = () => {
  // create a use ref to scroll left and right
  const marqueeRef = useRef(null);
  // const [direction, setDirection] = useState(1);
  let direction = 1;
  let stop = false;

  const toggleDirection = () => {
    // setDirection(prev=>prev*-1);
    direction *= -1;
  };

  const animateScroll = () => {
    const element = marqueeRef.current;
    if (element && !stop) {
      // smooth scroll to the left
      const maxScrollLeft = element.scrollWidth - element.clientWidth;

      element.scrollLeft += direction;
      // check if the scroll has reached the end
      if (element.scrollLeft <= 0 || element.scrollLeft >= maxScrollLeft) {
        element.scrollLeft -= direction;
        toggleDirection();
      }
    }
    // requestAnimationFrame(animateScroll);
  };

  // requestAnimationFrame(animateScroll);

  useEffect(() => {
    // set an event listener to stop the animation when the user hovers over the carousel
    marqueeRef.current.addEventListener("mouseover", () => {
      stop = true;
    });

    marqueeRef.current.addEventListener("mouseout", () => {
      stop = false;
    });
    // set an event listener to stop the animation when the user touches the carousel
    marqueeRef.current.addEventListener("touchstart", () => {
      stop = true;
    });

    marqueeRef.current.addEventListener("touchend", () => {
      stop = false;
    });

    const interval = setInterval(animateScroll, 50);

    return () => {
      // remove the event listeners
      marqueeRef.current.removeEventListener("mouseover", () => {
        stop = true;
      });
      marqueeRef.current.removeEventListener("mouseout", () => {
        stop = false;
      });

      marqueeRef.current.removeEventListener("touchstart", () => {
        stop = true;
      });

      marqueeRef.current.removeEventListener("touchend", () => {
        stop = false;
      });
      return clearInterval(interval);
    };
  }, []);

  return (
    <Element name="brands">
      <div>
        <div className="marquee-portfolio-header">
          <h1>Brands We Work With</h1>
        </div>

        <div className="brand-carousel">
          <div className="marquee-logo-container">
            <div ref={marqueeRef}>
              {logos.map((logo, index) => (
                <img
                  key={index}
                  // className={
                  //   index === currentIndex % logos.length
                  //     ? "marquee-logo active"
                  //     : "marquee-logo"
                  // }
                  className="marquee-logo"
                  src={logo}
                  alt={`Brand Logo ${index + 1}`}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </Element>
  );
};

export default SlidingBrandCarousel;
