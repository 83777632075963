import { Component } from "react";
import "./style.css";

const Banner = () => {
  const handleGetQuote = () => {
    // scroll to the contact section
    const contact = document.querySelector("#contact");
    contact.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <section id="banner" className="banner__container">
      <div className="container">
        <h1>
          Let's Boost Your Care
          <br /> With The Latest and Safest Technology
        </h1>
        <a href="mailto:info@tritameds.com">
          <button className="btn btn-primary" onClick={handleGetQuote}>
            Get In Touch
          </button>
        </a>
      </div>
    </section>
  );
};
/*
class Banner extends Component {
  render() {
    return (
      <section id="banner" className="banner__container">
        <div className="container">
          <h1>
            Let's Boost Your Business
            <br /> With The Latest Technology
          </h1>
          <button className="btn btn-primary">Get In Touch</button>
        </div>
      </section>
    );
  }
}
*/
export default Banner;
