// import { Component } from "react";
import { useState } from "react";
import { RiMenu3Line } from "react-icons/ri";

const Links = () => {
  const links = [
    "home",
    "about",
    "services",
    "portfolio",
    // "testimony",
    // "news",
    "contact",
  ];

  const [linksVisibility, setLinksVisibility] = useState(false);
  const [activeLink, setActiveLink] = useState("home"); // state for active link

  const toggleVisibility = () => {
    setLinksVisibility(!linksVisibility);
  };
  const handleLinkClick = (link) => {
    setActiveLink(link); // set clicked link as active
    setLinksVisibility(false); // close the menu on link click
  };

  return (
    <>
      <div className="links__button hide__button">
        <button className="btn menu" onClick={toggleVisibility}>
          <RiMenu3Line />
        </button>
      </div>
      <div className={`links ${!linksVisibility ? "hide__links" : ""}`}>
        {links.map((link, index) => {
          return (
            <a
              className={activeLink === link ? "active" : ""}
              href={`#${link}`}
              onClick={() => handleLinkClick(link)} // set active link on click
              key={index}
            >
              {link}
            </a>
          );
        })}
      </div>
    </>
  );
};

export default Links;
/*
class Links extends Component {
  constructor() {
    super();
    this.links = [
      "home",
      "about",
      "services",
      "portfolio",
      "testimony",
      "news",
      "contact"
    ];
    this.state = {
      linksVisibility: false
    };
    this.toggleVisibility = this.toggleVisibility.bind(this);
  }
  toggleVisibility() {
    this.setState((lastState) => {
      return {
        linksVisibility: !this.state.linksVisibility
      };
    });
  }

  render() {
    return (
      <>
        <div className="links__button hide__button">
          <button className="btn menu" onClick={this.toggleVisibility}>
            <RiMenu3Line />
          </button>
        </div>
        <div
          className={`links ${
            !this.state.linksVisibility ? "hide__links" : ""
          }`}
        >
          {this.links.map((link, index) => {
            return (
              <a
                className={!index ? "active" : ""}
                href={`#${link}`}
                onClick={this.toggleVisibility}
              >
                {link}
              </a>
            );
          })}
        </div>
      </>
    );
  }
}
export default Links;
*/
